import React, { useState } from "react"
import { RoutePropagator, TitleBar } from "@shopify/app-bridge-react"
import { Page, Modal } from "@shopify/polaris"
import { ReactifyApp } from "@reactify-apps/reactify-core-app"

import { genericNavBar } from "../helpers/navigation"

import { ProductList } from "components/Product/ProductList"

const ProductsPage = props => {
  const { location } = props

  const [modal, setModal] = useState(false)

  const config = {
    ...genericNavBar,
    title: "Products",
  }

  const item = {}

  return (
    <Page title={config.title}>
      {location && <RoutePropagator location={location} />}
      <TitleBar {...config} />

      <ProductList item={item} />

      <Modal
        title={`View ${modal.format ? modal.format : "content"}`}
        open={modal.open}
        message={modal.message}
        onClose={() =>
          setModal({
            open: false,
          })
        }
      ></Modal>
    </Page>
  )
}

export default ReactifyApp.Firebase.withFirebase(ProductsPage)
